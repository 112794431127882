import React from "react";
import styles from "styles/main-activity/Icon.module.css";

const PhoneIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9878_3335)">
        <path
          className={styles.icon_fg_phone}
          d="M4.41333 7.99753C5.37333 9.8842 6.92 11.4242 8.80667 12.3909L10.2733 10.9242C10.4533 10.7442 10.72 10.6842 10.9533 10.7642C11.7 11.0109 12.5067 11.1442 13.3333 11.1442C13.7 11.1442 14 11.4442 14 11.8109V14.1375C14 14.5042 13.7 14.8042 13.3333 14.8042C7.07333 14.8042 2 9.73087 2 3.47087C2 3.1042 2.3 2.8042 2.66667 2.8042H5C5.36667 2.8042 5.66667 3.1042 5.66667 3.47087C5.66667 4.3042 5.8 5.1042 6.04667 5.85087C6.12 6.0842 6.06667 6.3442 5.88 6.53087L4.41333 7.99753Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9878_3335">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.804199)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PhoneIcon;
